<template>
  <div class="content">
    <div class="top">
      <p class="title">工作台</p>
      <div class="tabs">
        <div class="tab-item" :class="{active: active === 0}" @click="handleClick(0)">
          <div class="tab-label">
            <svg-icon iconClass="work" className="icon" />工单
          </div>
          <div class="tab-info flex_c_c">{{ workOrderCount }}</div>
        </div>
        <div class="tab-item" :class="{active: active === 1}" @click="handleClick(1)">
          <div v-if="schedule.unreadSchedule !== '0'" class="new"></div>
          <div class="tab-label">
            <svg-icon iconClass="todone" className="icon" />待办
          </div>
          <div class="tab-info flex_c_c">{{ schedule.unreadSchedule }}<span>/ {{ schedule.readSchedule }}</span></div>
        </div>
        <div class="tab-item" :class="{active: active === 2}" @click="handleClick(2)">
          <div v-if="message.unreadMsg !== '0'" class="new"></div>
          <div class="tab-label">
            <svg-icon iconClass="message" className="icon" />消息
          </div>
          <div class="tab-info flex_c_c">{{ message.unreadMsg }}<span>/ {{ message.readMsg }}</span></div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <WorkBillList v-if="active === 0"></WorkBillList>
      <Agency v-if="active === 1"></Agency>
      <Message v-else-if="active === 2" @read="read"></Message>
    </div>
  </div>
</template>
<script>
import WorkBillList from './WorkBillList.vue'
import Message from './Message.vue'
import Agency from './Agency.vue'
import { workBenchOverview } from '@/api/home'
export default {
  components: {
    WorkBillList,
    Agency,
    Message
  },
  data() {
    return {
      message: {},
      schedule: {},
      active: 0,
      workOrderCount: 0
    }
  },
  async created() {
    this.getWorkBenchOverview()
  },
  methods: {
    async getWorkBenchOverview() {
      try {
        const res = await workBenchOverview()
        if (res.success) {
          const readMsg = res.data.message.find((a) => a.type === 1).data.value
          const unreadMsg = res.data.message.find((a) => a.type === 0).data.value
          this.message = {
            unreadMsg,
            readMsg
          }
          const readSchedule = res.data.schedule.find((a) => a.type === 3).data
            .value
          const unreadSchedule = res.data.schedule.find((a) => a.type === 2).data
            .value
          this.schedule = {
            readSchedule,
            unreadSchedule
          }
          this.workOrderCount = res.data.workOrderCount
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    showUpComing() {
      this.$store.commit('CHANGE_UPCOME_DRAWER', true)
    },
    showMessage() {
      this.$store.commit('CHANGE_MESSAGE_DRAWER', true)
    },
    handleClick(v) {
      this.active = v
    },
    read() {
      this.getWorkBenchOverview()
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 6px 6px 6px 6px;
  overflow: hidden;
  .top {
    width: 100%;
    border-bottom: 1px solid #EEEEEE;
    box-sizing: border-box;
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      padding: 20px 0 19px 20px;
      box-sizing: border-box;
    }
    .tabs {
      width: calc(100% - 50px);
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
    .tab-item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 120px;
      height: 120px;
      border-radius: 20px 20px 0px 0px;
      background: #F7F7F7;
      color: #999999;
      cursor: pointer;
      .new {
        position: absolute;
        top: 8px;
        right: 10px;
        width: 28px;
        height: 14px;
        &::after {
          content: ' ';
          position: absolute;
          background: url('../../../assets/images/home/new.png');
          background-size: 100% 100%;
          width: 28px;
          height: 14px;
          top: 0;
        }
      }
      .tab-label {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        margin-top: 26px;
        .icon {
          font-size: 24px;
        }
      }
      .tab-info {
        width: 100px;
        height: 40px;
        background: rgba(255, 255, 255, .5);
        border-radius: 10px 10px 10px 10px;
        font-size: 16px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        margin-bottom: 15px;
        span {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          margin-left: 3px;
        }
      }
      & + .tab-item {
        margin-left: 15px;
      }
    }
    .active {
      position: relative;
      background: rgba(35, 149, 243, .2);
      color: rgba(35, 149, 243, 1);
      &::after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-bottom: 12px solid #fff;
        bottom: 0;
        left: 50%;
        margin-left: -10px;
        margin-bottom: -1px;
      }
    }
  }
  .bottom {
    width: 100%;
    height: calc(100% - 180px);
  }
}
</style>
