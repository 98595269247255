<template>
  <div class="container">
    <div class="left">
      <!-- <ChartsView></ChartsView> -->
      <div class="empty flex_c_c">
        <img src="@/assets/images/home/empty.png">
      </div>
    </div>
    <div class="right">
      <WorkTable></WorkTable>
    </div>
  </div>
</template>

<script>
// import ChartsView from './components/ChartsView.vue'
import WorkTable from './components/WorkTable.vue'
export default {
  components: {
    // ChartsView,
    WorkTable
  },
  data() {
    return {
    }
  },
  async created() {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: space-between;
  .left {
    // width: 1390px;
    flex: 1;
    height: 100%;
    margin-right: 10px;
    .empty {
      width: 100%;
      height: 100%;
      background: #FFFFFF;
      border-radius: 5px 5px 5px 5px;
      img {
        width: 300px;
        height: 300px;
      }
    }
  }
  .right {
    width: 440px;
    height: 100%;
  }
}
</style>
