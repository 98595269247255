<template>
  <div class="content">
    <div class="header">
      <div class="tab">
        <span :class="{active: !active}" @click="handleClick(0)">待办</span>
        <span :class="{active: active}" @click="handleClick(1)">已办</span>
      </div>
      <BaseFilter
        :isActive="isActive"
        :width="452"
        @sendFilter="sendFilter"
        @reset="reset"
      >
        <el-form ref="form" :model="filterList" label-width="56px">
          <el-form-item label="客户">
            <el-select
              v-model="filterList.companyIds"
              multiple
              placeholder="请选择"
              style="width: 320px"
            >
              <el-option
                v-for="item in companyDrapdown"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="优先级">
            <el-select
              v-model="filterList.priority"
              placeholder="请选择"
              style="width: 320px"
            >
              <el-option label="非常紧急" value="0"></el-option>
              <el-option label="紧急" value="1"></el-option>
              <el-option label="一般" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型">
            <el-select
              v-model="filterList.type"
              placeholder="请选择"
              style="width: 320px"
            >
              <el-option
                v-for="item in config.AgencyTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </BaseFilter>
    </div>
    <div v-loading="loading" class="slidebox">
      <div v-for="item in lists" :key="item.id" class="item" @click="openUrl(item)">
        <div class="top">
          <div class="left">
            <span class="level" :class="`level-${colorFormatter(item.priority) }`">{{ item.priority }}</span>
            <span class="type">{{ item.typeName }}</span>
            <span>客户：{{ item.companyId | filterCompany }}</span>
          </div>
          <span class="date">{{ item.createdOn.slice(0, item.createdOn.length - 3) }}</span>
        </div>
        <div class="bottom">
          <p>{{ item.description }}</p>
        </div>
      </div>
      <div v-if="total !== lists.length" class="more flex_c_c" @click="more">查看更多</div>
    </div>
  </div>
</template>

<script>
let that
const BaseFilter = () => import('@/components/filter/BaseFilter')
import { getSchedulePages } from '@/api/home'
import { getRouteByCode } from '@/utils/common'
import { mapGetters } from 'vuex'
import config from '@/utils/config'
export default {
  filters: {
    filterCompany(text) {
      const item = that.companyDrapdown.find((a) => a.value === text)
      if (item) {
        return item.label
      }
      return ''
    }
  },
  components: {
    BaseFilter
  },
  data() {
    return {
      config,
      active: 0,
      loading: false,
      pagerConfig: {
        pageIndex: 1,
        pageSize: 6
      },
      lists: [],
      total: 0,
      isActive: false,
      filterList: {
        companyIds: '',
        priority: '',
        type: ''
      }
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown'])
  },
  watch: {
    active() {
      this.getList(this.pagerConfig)
    }
  },
  mounted() {
    that = this
    this.getList(this.pagerConfig)
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
  },
  methods: {
    colorFormatter(priority) {
      const obj = {
        '非常紧急': 'T1',
        '紧急': 'T3',
        '一般': 'T2'
      }
      return obj[priority]
    },
    async getList(param) {
      try {
        this.loading = true
        param = {
          ...param,
          queryParam: {
            status: this.active,
            ...this.formatterFilter()
          }
        }
        const res = await getSchedulePages(param)
        if (res.success) {
          this.loading = false
          this.lists = res.data.list
          this.total = res.data.total
        } else {
          this.loading = false
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleClick(v) {
      this.active = v
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 6
      }
    },
    more() {
      const pages = {
        pageIndex: 1,
        pageSize: this.pagerConfig.pageSize + 6
      }
      this.pagerConfig = pages
      this.getList(pages)
    },
    openUrl({ menuCode, id: scheduleId, queryJson, type }) {
      const path = getRouteByCode(menuCode)
      const params = { menuCode, scheduleId, queryJson }
      this.$store.commit('SET_CONNNET', params)
      if (path !== this.$route.fullPath) {
        this.$router.push(path)
      }
      if (type === 1) {
        this.$store.dispatch('readConnnet')
      }
    },
    reset() {
      this.filterList = {
        priority: '',
        type: '',
        companyIds: ''
      }
      this.isActive = false
    },
    sendFilter() {
      if (
        this.filterList.priority !== '' ||
        this.filterList.type !== '' ||
        this.filterList.companyIds.length > 0
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      const pager = {
        pageIndex: 1,
        pageSize: 6
      }
      this.pager = pager
      this.getList(pager)
    },
    formatterFilter() {
      const { companyIds, priority, type } = this.filterList
      return {
        priority: priority !== '' ? priority : undefined,
        type: type !== '' ? type : undefined,
        companyIds: companyIds.length > 0 ? companyIds : undefined
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .reference {
  color: #999999 !important;
  width: 60px !important;
  font-size: 12px !important;
  & > .icon {
    font-size: 16px !important;
  }
  &.active {
    color: #2395F3 !important;
  }
}
.content {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  .header {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);
    height: 30px;
    margin: 10px auto;
    .tab {
      display: flex;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 100%;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        border: 1px solid #EEEEEE;
        box-sizing: border-box;
        cursor: pointer;
        &:first-child {
          border-radius: 4px 0px 0px 4px;
        }
        &:last-child {
          border-radius: 0px 4px 4px 0px;
        }
      }
      .active {
        background: #2395F3;
        color: #FFFFFF;
        border: 0;
      }
    }
  }
  .slidebox {
    width: 100%;
    height: calc(100% - 44px);
    padding: 0 20px;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    .item {
      width: 400px;
      min-height: 80px;
      height: auto;
      background: #F7F7F7;
      border-radius: 6px 6px 6px 6px;
      padding: 15px 20px;
      box-sizing: border-box;
      cursor: pointer;
      & + .item {
        margin-top: 10px;
      }
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      span {
        display: inline-block;
      }
      .left {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .level {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        padding: 0 6px;
        height: 20px;
        border-radius: 4px 4px 4px 4px;
        font-size: 12px;
        margin-right: 5px;
      }
      .level-T1 {
        background: rgba(254, 69, 61, 0.2);
        color: #fe453d;
      }
      .level-T2 {
        background: rgba(157, 103, 254, 0.2);
        color: #9d67fe;
      }
      .level-T3 {
        background: rgba(255, 116, 68, 0.2);
        color: rgba(255, 116, 68, 1);
      }
      .level-T4 {
        background: rgba(53, 186, 131, 0.2);
        color: rgba(53, 186, 131, 1);
      }
      .level-T5 {
        background: rgba(35, 149, 243, 0.2);
        color: rgba(35, 149, 243, 1);
      }
      .type {
        display: flex;
        align-items: center;
        width: auto;
        height: 20px;
        padding: 0 5px;
        box-sizing: border-box;
        background: rgba(35, 149, 243, .2);
        border-radius: 4px 4px 4px 4px;
        font-size: 12px;
        color: #2395F3;
        margin-right: 5px;
      }
      .date {
        display: flex;
        align-self: flex-start;
        whITe-space: nowrap;
        width: 120px;
        height: 19px;
      }
    }
    .bottom {
      width: 100%;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 10px;
      p {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .more {
      width: 80px;
      height: 30px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #2395F3;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395F3;
      cursor: pointer;
      margin: 30px auto 0;
      box-sizing: border-box;
    }
  }
}
</style>
