var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "header" }, [
      _c(
        "span",
        {
          class: { active: !_vm.active },
          on: {
            click: function ($event) {
              return _vm.handleClick(0)
            },
          },
        },
        [_vm._v("未读")]
      ),
      _c(
        "span",
        {
          class: { active: _vm.active },
          on: {
            click: function ($event) {
              return _vm.handleClick(1)
            },
          },
        },
        [_vm._v("已读")]
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "slidebox",
      },
      [
        _vm._l(_vm.lists, function (item, $index) {
          return _c("div", { key: item.id, staticClass: "item" }, [
            _c("div", { staticClass: "top" }, [
              _c("div", { staticClass: "left" }, [
                _c("span", { staticClass: "company" }, [
                  _vm._v(
                    "客户：" + _vm._s(_vm._f("filterCompany")(item.companyId))
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(item.createdOn.slice(0, item.createdOn.length - 3))
                  ),
                ]),
              ]),
              !_vm.active
                ? _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/loyout/unReadMsgIcon.png"),
                        },
                      }),
                      _c(
                        "el-link",
                        {
                          attrs: { underline: false, type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.read(item.id)
                            },
                          },
                        },
                        [_vm._v("标为已读")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "bottom" }, [
              _c("input", {
                staticClass: "exp",
                attrs: { id: `exp${$index}`, type: "checkbox" },
              }),
              _c("div", { staticClass: "text" }, [
                _vm.byteCount(item.description)
                  ? _c("label", {
                      staticClass: "btn",
                      attrs: { for: `exp${$index}` },
                    })
                  : _vm._e(),
                _vm._v(" " + _vm._s(item.description) + " "),
              ]),
            ]),
          ])
        }),
        _vm.total !== _vm.lists.length
          ? _c(
              "div",
              { staticClass: "more flex_c_c", on: { click: _vm.more } },
              [_vm._v("查看更多")]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }