var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "right" }, [_c("WorkTable")], 1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "empty flex_c_c" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/home/empty.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }