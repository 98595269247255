<template>
  <div class="content">
    <div class="header">
      <span :class="{active: !active}" @click="handleClick(0)">未读</span>
      <span :class="{active: active}" @click="handleClick(1)">已读</span>
    </div>
    <div v-loading="loading" class="slidebox">
      <div v-for="(item, $index) in lists" :key="item.id" class="item">
        <div class="top">
          <div class="left">
            <span class="company">客户：{{ item.companyId | filterCompany }}</span>
            <span>{{ item.createdOn.slice(0, item.createdOn.length - 3) }}</span>
          </div>
          <div v-if="!active" class="right">
            <img src="@/assets/images/loyout/unReadMsgIcon.png" />
            <el-link
              :underline="false"
              type="primary"
              @click="read(item.id)"
            >标为已读</el-link
            >
          </div>
        </div>
        <div class="bottom">
          <input :id="`exp${$index}`" class="exp" type="checkbox" />
          <div class="text">
            <label v-if="byteCount(item.description)" class="btn" :for="`exp${$index}`"></label>
            {{ item.description }}
          </div>
        </div>
      </div>
      <div v-if="total !== lists.length" class="more flex_c_c" @click="more">查看更多</div>
    </div>
  </div>
</template>

<script>
let that
import { getMessagePages, readMessage } from '@/api/home'
import { mapGetters } from 'vuex'
export default {
  filters: {
    filterCompany(text) {
      const item = that.companyOptions.find((a) => a.value === text)
      if (item) {
        return item.label
      }
      return ''
    }
  },
  data() {
    return {
      active: 0,
      loading: false,
      pagerConfig: {
        pageIndex: 1,
        pageSize: 6
      },
      lists: [],
      total: 0
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown']),
    companyOptions() {
      return this.companyDrapdown
    }
  },
  watch: {
    active() {
      this.getList(this.pagerConfig)
    }
  },
  mounted() {
    that = this
    this.getList(this.pagerConfig)
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
  },
  methods: {
    async getList(param) {
      try {
        this.loading = true
        param = {
          ...param,
          queryParam: {
            hasBeenRead: !!this.active
          }
        }
        const res = await getMessagePages(param)
        if (res.success) {
          this.loading = false
          this.lists = res.data.list
          this.total = res.data.total
        } else {
          this.loading = false
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleClick(v) {
      this.active = v
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 6
      }
    },
    more() {
      const pages = {
        pageIndex: 1,
        pageSize: this.pagerConfig.pageSize + 6
      }
      this.pagerConfig = pages
      this.getList(pages)
    },
    // 长度超过107 超过两行
    byteCount(str) {
      //  <summary>获得字符串实际长度，中文2，英文1</summary>
      //  <param name="str">要获得长度的字符串</param>
      let realLength = 0
      const len = str.length
      let charCode = -1
      for (let i = 0; i < len; i++) {
        charCode = str.charCodeAt(i)
        if (charCode >= 0 && charCode <= 128) {
          realLength += 1
        } else {
          realLength += 2
        }
      }
      return realLength > 100
    },
    async read(id) {
      try {
        const res = await readMessage({ id })
        if (res.success) {
          this.$message.success('操作成功')
          const param = {
            pageIndex: 1,
            pageSize: 6
          }
          this.pagerConfig = param
          this.getList(param)
          this.$emit('read')
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  .header {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 40px);
    height: 24px;
    margin: 10px auto;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 24px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      border: 1px solid #EEEEEE;
      box-sizing: border-box;
      cursor: pointer;
      &:first-child {
        border-radius: 4px 0px 0px 4px;
      }
      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }
    }
    .active {
      background: #2395F3;
      color: #FFFFFF;
      border: 0;
    }
  }
  .slidebox {
    width: 100%;
    height: calc(100% - 44px);
    padding: 0 20px;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    .item {
      width: 400px;
      min-height: 80px;
      height: auto;
      background: #F7F7F7;
      border-radius: 6px 6px 6px 6px;
      padding: 15px 20px;
      box-sizing: border-box;
      & + .item {
        margin-top: 10px;
      }
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      span {
        display: inline-block;
      }
      .left {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .company {
          margin-right: 15px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        font-size: 14px;
      }
    }
    .bottom {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-top: 10px;
      display: flex;
      .text {
        word-wrap: break-word;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .text::before {
        content: "";
        height: calc(100% - 24px);
        float: right;
      }
      .btn {
        float: right;
        clear: both;
        margin-left: 10px;
        font-size: 14px;
        line-height: 26px;
        color: #2395f3;
        cursor: pointer;
      }
      .btn::before {
        content: "展开";
      }
      .exp {
        display: none;
      }
      .exp:checked + .text {
        -webkit-line-clamp: 999;
      }
      .exp:checked + .text .btn::before {
        content: "收起";
      }
    }
    .more {
      width: 80px;
      height: 30px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #2395F3;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395F3;
      cursor: pointer;
      margin: 30px auto 0;
      box-sizing: border-box;
    }
  }
}
</style>
