var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c(
          "el-select",
          {
            staticStyle: { width: "100px" },
            attrs: { placeholder: "请选择", clearable: "" },
            on: { change: _vm.onChange },
            model: {
              value: _vm.status,
              callback: function ($$v) {
                _vm.status = $$v
              },
              expression: "status",
            },
          },
          _vm._l(_vm.config.workBillStatusOptions, function (item) {
            return _c("el-option", {
              key: item.value,
              attrs: { label: item.label, value: item.value },
            })
          }),
          1
        ),
        _c(
          "div",
          { staticClass: "search" },
          [
            _c("el-input", {
              staticClass: "input-search",
              attrs: { placeholder: "请输入内容搜索" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.search.apply(null, arguments)
                },
              },
              model: {
                value: _vm.searchVal,
                callback: function ($$v) {
                  _vm.searchVal = $$v
                },
                expression: "searchVal",
              },
            }),
            _c(
              "div",
              { staticClass: "btn-search", on: { click: _vm.search } },
              [_c("i", { staticClass: "el-icon-search" })]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "slidebox",
      },
      [
        _vm._l(_vm.lists, function (item, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass: "item",
              on: {
                click: function ($event) {
                  return _vm.openUrl(item)
                },
              },
            },
            [
              _c("div", { staticClass: "top" }, [
                _c("p", { staticClass: "billNo" }, [
                  _vm._v(" " + _vm._s(item.workOrderNo) + " "),
                  _c(
                    "span",
                    { staticClass: "status", class: `status-${item.status}` },
                    [
                      _vm._v(
                        " " + _vm._s(_vm._f("formatStatus")(item.status)) + " "
                      ),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "date" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.createdOn
                          ? item.createdOn.slice(0, item.createdOn.length - 3)
                          : ""
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("p", { staticClass: "billDes" }, [
                _vm._v(_vm._s(item.description)),
              ]),
            ]
          )
        }),
        _vm.total !== _vm.lists.length
          ? _c(
              "div",
              { staticClass: "more flex_c_c", on: { click: _vm.more } },
              [_vm._v("查看更多")]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }