var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("div", { staticClass: "tab" }, [
          _c(
            "span",
            {
              class: { active: !_vm.active },
              on: {
                click: function ($event) {
                  return _vm.handleClick(0)
                },
              },
            },
            [_vm._v("待办")]
          ),
          _c(
            "span",
            {
              class: { active: _vm.active },
              on: {
                click: function ($event) {
                  return _vm.handleClick(1)
                },
              },
            },
            [_vm._v("已办")]
          ),
        ]),
        _c(
          "BaseFilter",
          {
            attrs: { isActive: _vm.isActive, width: 452 },
            on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
          },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: { model: _vm.filterList, "label-width": "56px" },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "客户" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "320px" },
                        attrs: { multiple: "", placeholder: "请选择" },
                        model: {
                          value: _vm.filterList.companyIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.filterList, "companyIds", $$v)
                          },
                          expression: "filterList.companyIds",
                        },
                      },
                      _vm._l(_vm.companyDrapdown, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "优先级" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "320px" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.filterList.priority,
                          callback: function ($$v) {
                            _vm.$set(_vm.filterList, "priority", $$v)
                          },
                          expression: "filterList.priority",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "非常紧急", value: "0" },
                        }),
                        _c("el-option", {
                          attrs: { label: "紧急", value: "1" },
                        }),
                        _c("el-option", {
                          attrs: { label: "一般", value: "2" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "类型" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "320px" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.filterList.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.filterList, "type", $$v)
                          },
                          expression: "filterList.type",
                        },
                      },
                      _vm._l(_vm.config.AgencyTypeOptions, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "slidebox",
      },
      [
        _vm._l(_vm.lists, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "item",
              on: {
                click: function ($event) {
                  return _vm.openUrl(item)
                },
              },
            },
            [
              _c("div", { staticClass: "top" }, [
                _c("div", { staticClass: "left" }, [
                  _c(
                    "span",
                    {
                      staticClass: "level",
                      class: `level-${_vm.colorFormatter(item.priority)}`,
                    },
                    [_vm._v(_vm._s(item.priority))]
                  ),
                  _c("span", { staticClass: "type" }, [
                    _vm._v(_vm._s(item.typeName)),
                  ]),
                  _c("span", [
                    _vm._v(
                      "客户：" + _vm._s(_vm._f("filterCompany")(item.companyId))
                    ),
                  ]),
                ]),
                _c("span", { staticClass: "date" }, [
                  _vm._v(
                    _vm._s(item.createdOn.slice(0, item.createdOn.length - 3))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "bottom" }, [
                _c("p", [_vm._v(_vm._s(item.description))]),
              ]),
            ]
          )
        }),
        _vm.total !== _vm.lists.length
          ? _c(
              "div",
              { staticClass: "more flex_c_c", on: { click: _vm.more } },
              [_vm._v("查看更多")]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }