<template>
  <div class="content">
    <div class="header">
      <el-select
        v-model="status"
        style="width: 100px"
        placeholder="请选择"
        clearable
        @change="onChange"
      >
        <el-option
          v-for="item in config.workBillStatusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="search">
        <el-input
          v-model="searchVal"
          placeholder="请输入内容搜索"
          class="input-search"
          @keydown.enter.native="search"
        ></el-input>
        <div class="btn-search" @click="search">
          <i class="el-icon-search"></i>
        </div>
      </div>
    </div>
    <div v-loading="loading" class="slidebox">
      <div
        v-for="(item, i) in lists"
        :key="i"
        class="item"
        @click="openUrl(item)"
      >
        <div class="top">
          <p class="billNo">
            {{ item.workOrderNo }}
            <span class="status" :class="`status-${item.status}`">
              {{ item.status | formatStatus }}
            </span>
          </p>
          <p class="date">
            {{
              item.createdOn
                ? item.createdOn.slice(0, item.createdOn.length - 3)
                : ""
            }}
          </p>
        </div>
        <p class="billDes">{{ item.description }}</p>
      </div>
      <div v-if="total !== lists.length" class="more flex_c_c" @click="more">查看更多</div>
    </div>
  </div>
</template>
<script>
import { getWorkOrderPages } from '@/api/home'
import { getRouteByCode } from '@/utils/common'
import config from '@/utils/config'
export default {
  filters: {
    formatStatus(status) {
      let text = ''
      switch (status) {
        case 0:
          text = '新增'
          break
        case 1:
          text = '处理中'
          break
        case 2:
          text = '通过'
          break
        case 3:
          text = '关闭'
          break
        case 4:
          text = '撤回'
          break
      }
      return text
    }
  },
  data() {
    return {
      config,
      lists: [],
      pagerConfig: {
        pageIndex: 1,
        pageSize: 6
      },
      loading: false,
      total: 0,
      status: '',
      searchVal: ''
    }
  },
  created() {
    this.getList(this.pagerConfig)
  },
  methods: {
    async getList(param) {
      this.loading = true
      try {
        param = {
          ...param,
          queryParam: {
            ...this.formatterFilter()
          }
        }
        const res = await getWorkOrderPages(param)
        this.loading = false
        if (res.success) {
          this.lists = res.data.list
          this.total = res.data.total
        } else {
          this.$message.erroe(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    more() {
      const pages = {
        pageIndex: 1,
        pageSize: this.pagerConfig.pageSize + 6
      }
      this.pagerConfig = pages
      this.getList(pages)
    },
    openUrl({ menuCode = config.menuCode.GD, workOrderNo }) {
      const path = getRouteByCode(menuCode)
      // console.log(path, menuCode, btnCode)
      const params = { menuCode, workOrderNo }
      this.$store.commit('SET_CONNNET', params)
      if (path !== this.$route.fullPath) {
        this.$router.push(path)
      }
    },
    formatterFilter() {
      return {
        search: this.searchVal || undefined,
        status: this.status !== '' ? this.status : undefined
      }
    },
    onChange() {
      const pager = {
        pageIndex: 1,
        pageSize: 6
      }
      this.pagerConfig = pager
      this.getList(pager)
    },
    search() {
      const pager = {
        pageIndex: 1,
        pageSize: 6
      }
      this.pagerConfig = pager
      this.getList(pager)
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  padding: 0 0 20px;
  box-sizing: border-box;
  .header {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 40px);
    height: 30px;
    margin: 10px auto;
    &:deep(.el-input__inner) {
      height: 30px;
    }
    &:deep(.el-input__suffix) {
      top: 5px;
    }
    &:deep(.el-input__icon) {
      line-height: inherit;
    }
    &:deep(.el-input__suffix-inner) {
      display: inline-block;
    }
    .search {
      position: relative;
      display: flex;
      width: 160px;
      margin-left: 5px;
      .input-search {
        width: 126px;
        height: 30px !important;
        /deep/ .el-input__inner {
          height: 30px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .btn-search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 1px solid #dbe4f2;
        border-left: 0;
        border-radius: 0 4px 4px 0;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
  .slidebox {
    width: 100%;
    height: calc(100% - 44px);
    padding: 0 20px;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
  }
  .item {
    width: 100%;
    height: 80px;
    padding: 16px 20px;
    border-radius: 6px 6px 6px 6px;
    background: #F7F7F7;
    cursor: pointer;
    box-sizing: border-box;
    & + .item {
      margin-top: 8px;
    }
  }
  .top {
    width: 100%;
    height: 19px;
    display: flex;
    justify-content: space-between;
    .billNo {
      display: flex;
      align-items: center;
      width: auto;
      height: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .status {
      display: inline-block;
      font-size: 12px;
      padding: 0 7px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      margin-left: 5px;
    }
    .status-0 {
      background: rgba(255, 116, 68, 0.1);
      color: #ff7444;
    }
    .status-1 {
      background: rgba(35, 149, 243, 0.1);
      color: #2395f3;
    }
    .status-2 {
      background: rgba(53, 186, 131, .1);
      color: #35BA83;
    }
    .status-3 {
      background: rgba(153, 153, 153, .1);
      color: #999999;
    }
    .status-4 {
      background: rgba(254, 69, 61, .1);
      color: #FE453D;
    }
    .date {
      width: auto;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  .billDes {
    width: auto;
    height: 19px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
  }
  .more {
      width: 80px;
      height: 30px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #2395F3;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395F3;
      cursor: pointer;
      margin: 30px auto 0;
      box-sizing: border-box;
    }
}
</style>
