var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "top" }, [
      _c("p", { staticClass: "title" }, [_vm._v("工作台")]),
      _c("div", { staticClass: "tabs" }, [
        _c(
          "div",
          {
            staticClass: "tab-item",
            class: { active: _vm.active === 0 },
            on: {
              click: function ($event) {
                return _vm.handleClick(0)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "tab-label" },
              [
                _c("svg-icon", {
                  attrs: { iconClass: "work", className: "icon" },
                }),
                _vm._v("工单 "),
              ],
              1
            ),
            _c("div", { staticClass: "tab-info flex_c_c" }, [
              _vm._v(_vm._s(_vm.workOrderCount)),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "tab-item",
            class: { active: _vm.active === 1 },
            on: {
              click: function ($event) {
                return _vm.handleClick(1)
              },
            },
          },
          [
            _vm.schedule.unreadSchedule !== "0"
              ? _c("div", { staticClass: "new" })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "tab-label" },
              [
                _c("svg-icon", {
                  attrs: { iconClass: "todone", className: "icon" },
                }),
                _vm._v("待办 "),
              ],
              1
            ),
            _c("div", { staticClass: "tab-info flex_c_c" }, [
              _vm._v(_vm._s(_vm.schedule.unreadSchedule)),
              _c("span", [_vm._v("/ " + _vm._s(_vm.schedule.readSchedule))]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "tab-item",
            class: { active: _vm.active === 2 },
            on: {
              click: function ($event) {
                return _vm.handleClick(2)
              },
            },
          },
          [
            _vm.message.unreadMsg !== "0"
              ? _c("div", { staticClass: "new" })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "tab-label" },
              [
                _c("svg-icon", {
                  attrs: { iconClass: "message", className: "icon" },
                }),
                _vm._v("消息 "),
              ],
              1
            ),
            _c("div", { staticClass: "tab-info flex_c_c" }, [
              _vm._v(_vm._s(_vm.message.unreadMsg)),
              _c("span", [_vm._v("/ " + _vm._s(_vm.message.readMsg))]),
            ]),
          ]
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _vm.active === 0 ? _c("WorkBillList") : _vm._e(),
        _vm.active === 1
          ? _c("Agency")
          : _vm.active === 2
          ? _c("Message", { on: { read: _vm.read } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }